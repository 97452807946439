<template lang="pug">
  .payment
    .payment__body
      .payment__code
        img(
          :src='host+qrcodePath'
          alt='QR-код для оплаты'
          v-if="qrcodePath"
        )
      p.payment__text(v-if="qrcodePath") Оплатить услугу Вы можете по QR-коду выше, в нашем зале, либо с помощью банковского перевода.
      p.payment__text.payment__topspace(v-else) Форма успешно заполнена.<br> Обратитесь к оператору для проверки.
    .payment__footer
      router-link.success__button.button.is-large.is-upper(
          to='/'
        ) На главный экран
      //- button.payment__button.button.is-large.is-wide.is-upper(
      //-   @click.prevent='print'
      //- ) Распечатать анкету и счет

</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import config from "@/config.js";

export default {
  data: () => ({
    image: null,
    host: config.host + '/',
  }),
  mounted() {
    const contentEl = document.querySelector('.app__content .content__body');
    if (this.qrcodePath) {
      contentEl.classList.remove('content__body-auto');
    } else {
      contentEl.classList.add('content__body-auto');
    }
  },
  beforeDestroy() {
    const contentEl = document.querySelector('.app__content .content__body');
    contentEl.classList.remove('content__body-auto');
    this.setQRCode("");
  },
  computed: {
    ...mapGetters({
      formData: "form/data",
      serviceId: "services/current",
      documentId: "document/getId",
      paymentPath: "document/getPaymentPath",
      qrcodePath: "document/getQrcodePath",
    })
  },
  methods: {
    ...mapActions({
      setCompleted: "stages/setCompleted",
    }),
    ...mapMutations({
      setQRCode: "document/setQrcodePath"
    }),
    loadImage() {
      return new Promise(resolve => {
        setTimeout(() => {
          // console.log("--- FORM DATA", {
          //   form: this.formData,
          //   serviceId: this.serviceId
          // });

          resolve("/img/order.jpg");
        }, 1000);
      });
    },
    async print() {
      //const popup = window.open("about:blank", "_new");
      //const newDocument = `<body onload="window.print()" onafterprint="window.close()"><img src="${this.image}" /></body>`;

      // popup.document.write(newDocument);
      // popup.document.close();

      await axios.get(`http://localhost:1447/print/${this.documentId}/${this.paymentPath}`)


      this.setCompleted("pay");
      this.$router.push("/success");
    }
  },
  async created() {
    this.image = await this.loadImage();
  }
};
</script>

<style lang="scss">
.payment {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    flex-grow: 1;
  }

  &__text {
    color: $black;
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 70px;
  }

  &__code {
    img {
      display: block;
      width: 185px;
      height: 185px;
      left: 0;
      right: 0;
      margin: auto;
      margin-bottom: 35px;
    }
  }

  &__topspace {
    margin-top: 40%;
    text-align: center;
  }
}
</style>
