<template lang="pug">
  .box
    Loader(v-if='loading')
    Payment(v-else)
</template>

<script>
import Payment from "@/components/Payment.vue";

export default {
  data: () => ({
    loading: true
  }),
  components: {
    Payment
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
};
</script>
